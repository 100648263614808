// @ts-check

import axios from '@common/clients/propertyServiceClient.js';
import { sortBy } from '@common/utils/sort.js';

/**
 * @typedef {import('@common/clients/propertyService/models/AccountCreateInput.js').default} AccountCreateInput
 * @typedef {import('@common/clients/propertyService/models/AccountUpdateInput.js').default} AccountUpdateInput
 * @typedef {import('@common/clients/propertyService/models/AddAccountUserInput.js').default} AddAccountUserInput
 * @typedef {import('@common/clients/propertyService/models/AddAccountListingSupplierInput.js').default} AddAccountListingSupplierInput
 * @typedef {import('@common/clients/propertyService/models/GetAccountAssociations.js').default} GetAccountAssociations
 * @typedef {import('@common/clients/propertyService/models/GetAccountOutput.js').default} GetAccountOutput
 */

/**
 * @typedef {{
 * accountSettings: boolean,
 * accountStatistics: boolean,
 * canPublish: boolean,
 * investmentListing: boolean,
 * marketStatistics: boolean,
 * officeSpaceListing: boolean,
 * rentalListing: boolean,
 * salesListing: boolean,
 * shownOnWebsite: boolean,
 * }} AccountPermissionsModel
 */

/**
 * @typedef {{
 * ID: string,
 * accountType: (1|2|3|4|5),
 * billingId: string,
 * email: string,
 * name: string,
 * organizationID: string,
 * organizationName: string,
 * permissions: AccountPermissionsModel,
 * phoneNumber: string
 * supplierAccountId: string,
 * }} GetAccountsModel
 */

/**
 * Returns list of accounts associated with the user
 * @returns {Promise<GetAccountAssociations[]>} Populated model from API
 */
export const getAssociations = async () => {
  const result = await axios.get('/accounts/associations');
  return result.data.accounts.sort(sortBy('name'));
};

/**
 * Get a single account by id
 * @param {string} accountId Accound ID
 * @returns {Promise<GetAccountOutput>} Populated model from API
 */
export const getAccount = async (accountId) => {
  const result = await axios.get(`/accounts/${accountId}`);
  return result.data;
};

/**
 * Returns a list of accounts
 * @returns {Promise<GetAccountsModel[]>} Populated model from API
 */
export const getAccounts = async () => {
  const result = await axios.get('/accounts');
  return result.data.accounts == null ? [] : result.data.accounts.sort(sortBy('name'));
};

/**
 * Adds...
 * @param {AccountCreateInput} model Account data being created
 * @returns {Promise<string>} Id on the new account that has been created
 */
export const addAccount = async (model) => {
  const result = await axios.post('/accounts', model);
  return result.data.ID;
};

/**
 * Updates...
 * @param {string} id Account id that being updated
 * @param {AccountUpdateInput} model Account data being updated AccountUpdateInput
 */
export const updateAccount = async (id, model) => {
  await axios.put(`/accounts/${id}`, model);
};

/**
 * Get an accounts permissions
 * @param {string} accountId Accound ID
 * @returns {Promise<AccountPermissionsModel>} Account permissions
 */
export const getAccountPermissions = async (accountId) => {
  const result = await axios.get(`/accounts/${accountId}/permissions`);
  return result.data;
};

/**
 * Update an accounts permissions
 * @param {string} accountId Accound ID
 * @param {AccountPermissionsModel} permissions Account permissions
 */
export const updateAccountPermission = async (accountId, permissions) => {
  await axios.put(`/accounts/${accountId}/permissions`, permissions);
};

// /**
//  * This is hardcoded in meta.js
//  */
// export const getAccountTypes = async () => {
//   // const result = await axios.get('/accounts/account-types');
//   // return result.data.accountTypes;
// };

/**
 * Add user to account
 * @param {string} accountId Accound ID
 * @param {string} userId User ID
 * @param {number} accountRoleId Account role ID
 */
export const addAccountUser = async (accountId, userId, accountRoleId) => {
  /** @type {AddAccountUserInput} */
  const model = {
    accountRole: accountRoleId,
    userID: userId,
  };
  await axios.post(`/accounts/${accountId}/users`, model);
};

/**
 * Remove user from account
 * @param {string} accountId Account ID
 * @param {string} userId User ID
 */
export const deleteAccountUser = async (accountId, userId) => {
  await axios.delete(`/accounts/${accountId}/users/${userId}`);
};

/**
 * Add listing supplier to account
 * @param {string} accountId Accound ID
 * @param {string} listingSupplierId Listing supplier ID
 */
export const addAccountListingSupplier = async (accountId, listingSupplierId) => {
  /** @type {AddAccountListingSupplierInput} */
  const model = {
    ID: listingSupplierId,
  };
  await axios.post(`/accounts/${accountId}/listing-suppliers`, model);
};

/**
 * Remove listing supplier from account
 * @param {string} accountId Accound ID
 * @param {string} listingSupplierId Listing supplier ID
 */
export const deleteAccountListingSupplier = async (accountId, listingSupplierId) => {
  await axios.delete(`/accounts/${accountId}/listing-suppliers/${listingSupplierId}`);
};
