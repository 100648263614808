// @ts-check

import { useT } from '@common/i18n/i18n.js';
const t = useT();

/**
 * @typedef {{
 *  isAxios: boolean,
 *  isCancelled: boolean,
 *  status: number|null,
 *  statusText: string|null,
 *  exception: object,
 *  url: string|null,
 *  message: string|null,
 * }} axiosError
 */

/**
 * @param {object} error Error from Axios to be handled
 * @returns {Promise<axiosError>} Error object to be used in notify.js
 */
export const handleAxiosReject = (error) => {
  /**
   * @type {axiosError}
   */
  const rejectObj = {
    isAxios: true, // Used when showing error in notify.js
    isCancelled: false,
    status: null,
    statusText: null,
    exception: null, // error.response.data
    url: null,
    message: null, // General message to be displayed when calling notifyAxiosToUser('', '', err);
  };

  if (error.response) {
    if (error.response.data.code != null) {
      // Error from backend, show message for this.
      rejectObj.message = error.response.data.message;
    } else {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      switch (error.response.status) {
        case 401:
          rejectObj.message = t('common.api.errors.notLoggedOn');
          break;
        case 404:
          // "404 page not found" is route not found. If something else it is cached when checking for "code" above from here
          if (error.response.data === '404 page not found') {
            rejectObj.message = t('common.api.errors.endpointNotFound', { endpoint: error.request.responseURL });
          }

          break;
      }
    }

    rejectObj.status = error.response.status;
    rejectObj.statusText = error.response.statusText;
    rejectObj.exception = error.response.data;
    rejectObj.url = error.request.responseURL;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
    // This could be a network error. Like the computer is offline
    if (error.code === 'ERR_CANCELED') {
      rejectObj.isCancelled = true;
      rejectObj.message = t('common.api.errors.requestCancelled');
    } else {
      rejectObj.message = t('common.api.errors.noResponseFromServer');
    }

    rejectObj.statusText = error.message;
    rejectObj.url = error.request.responseURL;
  } else {
    // Something happened in setting up the request that triggered an Error
    rejectObj.message = t('common.api.errors.somethingWentWrong');
    rejectObj.statusText = error.message;
  }

  return Promise.reject(rejectObj);
};
