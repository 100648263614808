// @ts-check

import { da } from './da.js';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: 'da',
});

export const setupI18n = (daTranslations) => {
  daTranslations.common = da;
  i18n.global.setLocaleMessage('da', daTranslations);

  return i18n;
};

export const useT = () => {
  return i18n.global.t;
};

/**
 *
 * @param {string} prefix The first part of the translation.
 * @returns {Function} Function to use for translation. const translate = useTranslation('aaa.bbb.labels'); translate('hepper');
 */
export const useTranslate = (prefix) => {
  /**
   *
   * @param {string} key Text to be inserted into the string as key after prefix
   * @param {number} choice (otional) Number to be displayed.
   * @returns {string} the translated string
   */
  return (key, choice) => i18n.global.t(`${prefix}.${key}`, choice);
};

/**
 *
 * @param {string} prefix The first part of the translation.
 * @returns {Function} Function to use for translation. const translate = useTranslation('aaa.bbb.labels'); translate('hepper');
 */
export const useTranslateError = (prefix) => {
  /**
   * @param {string} labelKey Text to be inserted into the string as key after prefix
   * @param {string} key Should be one of da.js => common.errors
   * @param {object} [options] More options to add to t
   * @returns {string} the translated string
   */
  return (labelKey, key = 'required', options = {}) => i18n.global.t(`common.global.errors.${key}`, { label: i18n.global.t(`${prefix}.${labelKey}`), ...options });
};
