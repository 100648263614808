<template>
  <div
    ref="root"
    :style="baseStyle"
    data-cy="debuginfo">
    <div class="u-flex">
      <span :title="JSON.stringify(userStore.user)">{{ userStore.user?.email }}</span>
      <span v-if="userStore.isETAdmin">(et-admin)</span>

      <div
        class="u-ml-a et-link"
        @click="showBox = !showBox">
        {{ showBox ? 'Skjul' : 'Vis' }}
      </div>
    </div>
    <div
      v-if="showBox"
      style="margin-top: 0.5rem">
      <div>
        <AccountSelect />
      </div>
      <div
        class="u-mt-2 u-flex u-gap-4">
        <EtCheckbox
          v-for="ar in metaStore.accountRoles"
          :key="ar.id"
          :model-value="userStore.activeUserRoles"
          :native-value="ar.id"
          :label="ar.name"
          :disabled="!userStore.isETAdmin"
          hide-error
          @update:model-value="userStore.setUserRoles($event, false)" />

        <div
          class="u-ml-a et-link"
          @click="setAsNormalUser">
          Alm. bruger
        </div>
      </div>
      <div class="u-mt-1">
        <div
          class="et-link"
          @click="showPermissions = !showPermissions">
          {{ showPermissions ? 'Skjul' : 'Vis' }} permissions
        </div>
        <pre v-if="showPermissions">{{ userStore.activePermissions }}</pre>
      </div>
    </div>
  </div>
</template>

<script setup>
  // @ts-check
  /**
   * This component is only for debugging. So we are not using i18n
   */
  import { EtCheckbox } from 'vue-ui-kit';
  import { ref } from 'vue';
  import AccountSelect from '@/components/account/AccountSelect.vue';
  import { useUserStore, useMetaStore } from '@common/store/index.js';
  const root = ref(null);
  const userStore = useUserStore();
  const metaStore = useMetaStore();
  const showPermissions = ref(false);
  const showBox = ref(false);

  const baseStyle = {
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%)',
    position: 'fixed',
    minWidth: '350px',
    padding: '0.75rem',
    right: '10px',
    bottom: '10px',
  };

  const setAsNormalUser = () => {
    userStore.setUserRoles([], false);
    userStore.setActiveAccountId(null, false);
  };
</script>
