// @ts-check

import { unref, watchEffect } from 'vue';
import { useKindeAuth } from './authPlugin.js';

/**
 * @typedef {import('vue-router').RouteLocation} RouteLocation
 */

/**
 * Stolen from https://github.com/auth0/auth0-vue/blob/0697f7a4e1d151ef95a315ff98354adb8db49381/src/utils.ts
 * Run watchEffect untill the watcher returns true, then stop the watch.
 * Once it returns true, the promise will resolve.
 * @param {Function} watcher Function to watch
 * @returns {Promise<any>} Returns promise
 */
function watchEffectOnceAsync(watcher) {
  return new Promise((resolve) => {
    watchEffectOnce(watcher, resolve);
  });
}

/**
 * Stolen from https://github.com/auth0/auth0-vue/blob/0697f7a4e1d151ef95a315ff98354adb8db49381/src/utils.ts
 * Run watchEffect untill the watcher returns true, then stop the watch.
 * Once it returns true, it will call the provided function.
 * @param {Function} watcher Function to watch
 * @param {Function} fn resolve function
 */
function watchEffectOnce(watcher, fn) {
  const stopWatch = watchEffect(() => {
    if (watcher()) {
      fn();
      stopWatch();
    }
  });
}

/**
 * @param {import('./authPlugin.js').KindeAuth} client KindeAuth client
 * @param {RouteLocation} to Vue router, where the route is going
 * @returns {Promise<boolean>} True if user is logged in
 */
const createGuardHandler = async (client, to) => {
  const fn = async () => {
    if (unref(client.isAuthenticated)) {
      return true;
    }

    await client.login(to);
    return false;
  };

  if (!unref(client.isLoading)) {
    return fn();
  }

  await watchEffectOnceAsync(() => !unref(client.isLoading));

  return fn();
};

/**
 * Waits for kinde to be called and then returns boolean for user logged in or not
 * @param {RouteLocation} to Vue router, where the route is going
 * @returns {Promise<boolean>} True if user is logged in
 */
export const authGuard = async (to) => {
  const kindeAuth = useKindeAuth();
  return createGuardHandler(kindeAuth, to);
};
