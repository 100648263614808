// @ts-check

import { defineStore } from 'pinia';
import { getAssociations, getAccounts } from '@common/clients/accountRepository.js';
import { notifyAxiosToUser } from '@common/utils/notify.js';
import { watch, ref, unref, computed } from 'vue';
import { useKindeAuth } from '@common/composables/auth/authPlugin.js';

const ACTIVEACCOUNT_ID = 'et-activeaccountid-v1';
const USERROLES_ID = 'et-activeuserroles-v1';

/**
 * @typedef {import('@kinde-oss/kinde-auth-pkce-js').KindeUser} KindeUser
 * @typedef {import('@common/clients/propertyService/models/GetAccountAssociations.js').default} GetAccountAssociations
 */

/**
 * @typedef {{
 * ID: string,
 * accountType: (1|2|3|4|5),
 * billingId: string,
 * email: string,
 * name: string,
 * organizationID: string,
 * organizationName: string,
 * permissions: {
 *  [key: string]: boolean
 * },
 * phoneNumber: string,
 * supplierAccountId: string,
 * userRoles?: (1|2|3)[]
 * }} AccountModel
 */

/**
 * Group accounts by id and combines their user_role into userRoles.
 * @param {GetAccountAssociations[]} accounts List of accounts to parse
 * @returns {AccountModel[]} array New array with combined accounts
 */
const parseAccounts = (accounts) => {
  // 1. step: make a "group by" id
  const map = new Map();
  accounts.forEach((item) => {
    const key = item.ID;
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  // 2. step: extract single account and combine user_role into array
  return Array.from(map.values()).map((a) => {
    const first = { ...a[0] }; // Make a copy of the 1. one
    first.userRoles = a.map(aa => aa.userRole);
    delete first.userRole;
    return first;
  });
};

export const useUserStore = defineStore('user', () => {
  const kinde = useKindeAuth();
  const isReady = ref(false); // When init method has run and everything is ready to be used
  const isAuthenticated = kinde.isAuthenticated; // Authenticated
  const isLoading = kinde.isLoading;
  const user = kinde.user; // Until user is authenticated, this is null
  /** @type {import('vue').Ref<string[]>} */
  const permissions = ref([]); // Contains if user is et-admin = ['admin']
  /** @type {import('vue').Ref<AccountModel[]>} */
  const accounts = ref([]); // Parsed accounts
  /** @type {import('vue').Ref<string | null>} */
  const activeAccountId = ref(null); // The accountid selected as the one the user is acting as
  /** @type {import('vue').Ref<number[]>} */
  const userRoles = ref([]); // If et-admin, then selected userRole is in this one

  /**
   * Returns active account.
   * If none found, it will return undefined
   * @type {import('vue').ComputedRef<AccountModel|undefined>} The active account
   */
  const activeAccount = computed(() => accounts.value.find(a => a.ID === activeAccountId.value));

  /**
   * Returns permissions for the active account.
   * @type {import('vue').ComputedRef<object>} Object with all permissions where each is either true or false
   */
  const activePermissions = computed(() => activeAccount.value?.permissions);

  /**
   * Returns user_roles for the active account
   * @type {import('vue').ComputedRef<number[]>} E.g. [1, 3] for admin and readonly
   */
  const activeUserRoles = computed(() => (isETAdmin.value ? userRoles.value : activeAccount.value?.userRoles) ?? []);

  /**
   * Tells if the user is et-admin
   * @type {import('vue').ComputedRef<boolean>}True if et-admin
   */
  const isETAdmin = computed(() => permissions.value.includes('admin'));

  /**
   * Returns true if user has given userrole
   * @example userStore.hasUserRole('admin')
   * @example userStore.hasUserRole('admin', 'readonly') // or
   * @param {('admin'|'member'|'readonly')[]} userRoles admin, member or readOnly
   * @returns {boolean} True if user has the role
   */
  const hasUserRole = (...userRoles) => {
    const roles = { admin: 1, member: 2, readonly: 3 }; // Hardcoded
    return userRoles.some(a => activeUserRoles.value.includes(roles[a.toLowerCase()]));
  };

  const hasPermission = (permission) => {
    return (activePermissions.value && activePermissions.value[permission]) ?? false;
  };

  /**
   * Set and saves active account id in localstorage
   * @param {string|null} accountId the id of the account selected
   * @param {boolean} [reload] True then browser reloads page
   */
  const setActiveAccountId = (accountId, reload) => {
    if (user.value?.id == null) {
      return;
    }

    activeAccountId.value = accountId;
    const activeids = getActiveAccountIds();

    activeids[user.value.id] = accountId;
    window.localStorage.setItem(ACTIVEACCOUNT_ID, JSON.stringify(activeids));
    if (reload) {
      window.location.reload();
    }
  };

  /**
   * Returns object with active account ids from localstorage
   * @returns {object} { 'auth|id': '<id of account>', 'auth|id': '<id of account>' }
   * @private
   */
  const getActiveAccountIds = () => {
    return JSON.parse(window.localStorage.getItem(ACTIVEACCOUNT_ID)) ?? {};
  };

  /**
   * Only used by et-admins
   * When user selects userroles, we store it in store and localstorage.
   * @param {number[]} newUserRoles Array with new user roles
   * @param {boolean} [reload] True, then page reloads
   */
  const setUserRoles = (newUserRoles, reload) => {
    userRoles.value = newUserRoles;
    window.localStorage.setItem(USERROLES_ID, JSON.stringify(newUserRoles));
    if (reload) {
      window.location.reload();
    }
  };

  /**
   * Resolves when store is ready for use. Can be used like this:
   * await userStore.whenReady();
   * ... do what you want with store info
   * @returns {Promise<any>} Returns promise
   */
  const whenReady = async () => {
    if (isReady.value) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const unwatchReady = watch(isReady, () => {
        if (isReady.value) {
          unwatchReady();
          unref(isReady);
          return resolve();
        }
      }, { immediate: true });
    });
  };

  /**
   * Initialized store with extra info like permissions and
   * accounts from backend
   * @private
   */
  const initUserInfo = async () => {
    if (user.value?.id == null) {
      return;
    }

    activeAccountId.value = getActiveAccountIds()[user.value.id];

    if (isReady.value) {
      return;
    }

    if (!isAuthenticated.value) {
      return;
    }

    // Get permissions from Kinde
    permissions.value = kinde.getPermissions();

    if (import.meta.env.VITE_SELECT_ONLY_ACCOUNT_TYPE === 'admin') {
      isReady.value = true;
      return;
    }

    let tmpAccounts = [];

    // If etadmin, then fetch all accounts. Else only users associated accounts
    if (isETAdmin.value) {
      try {
        tmpAccounts = await getAccounts();
      } catch (err) {
        notifyAxiosToUser('failedToRetrieve', 'accounts', err);
      }

      // Set userroles as admin
      userRoles.value = JSON.parse(window.localStorage.getItem(USERROLES_ID)) ?? [];
    } else {
      try {
        const associations = await getAssociations();
        tmpAccounts = parseAccounts(associations);
      } catch (err) {
        notifyAxiosToUser('failedToRetrieve', 'accountAssociations', err);
      }
    }

    accounts.value = tmpAccounts;

    if (accounts.value.length === 1) {
      setActiveAccountId(accounts.value[0].ID); // This one overrides above when getting from localstorage. As intended
    }

    isReady.value = true;
    unwatchInit();
  };

  // Initialize store
  const unwatchInit = watch(kinde.isLoading, () => {
    if (!kinde.isLoading.value) {
      if (kinde.isAuthenticated.value) {
        initUserInfo();
      } else {
        isReady.value = true;
      }
    }
  }, { immediate: true });

  return {
    isAuthenticated,
    isLoading,
    user,
    permissions,
    accounts,
    activeAccount,
    activePermissions,
    activeUserRoles,
    isETAdmin,
    // userRoles,
    hasUserRole,
    hasPermission,
    setActiveAccountId,
    setUserRoles,
    whenReady,
    isReady,
  };
});
