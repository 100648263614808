import { createRouter, createWebHistory } from 'vue-router';
import { privateRoutes, privateRouteNames } from './private';
import { publicRoutes, publicRouteNames } from './public';
import { privateGuard } from './routeGuards.js';

export const routes = [...publicRoutes, ...privateRoutes];
export const routeNames = { ...privateRouteNames, ...publicRouteNames };

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes,
});

router.beforeEach(privateGuard);

export default router;
