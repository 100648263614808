export const publicRoutes = [
  {
    path: '/noaccess',
    name: 'NoAccess',
    component: () => import('@/views/error/NoAccess.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/error/NotFound.vue'),
    meta: {
      public: true,
    },
  },
];

// Returns { HomePage: 'HomePage', NotAdmin: NotAdmin}
export const publicRouteNames = publicRoutes.map(r => r.name).reduce((a, v) => ({ ...a, [v]: v }), {});
