<script setup>
  // @ts-check
  import { computed } from 'vue';
  import { RouterView, useRoute } from 'vue-router';
  import TheNavigationBar from '@/layout/TheNavigationBar.vue';
  import KindeError from '@common/components/error/KindeError.vue';
  import DebugInfo from '@/components/debug/DebugInfo.vue';
  import { EtLoader } from 'vue-ui-kit';
  import { useKindeAuth } from '@common/composables/auth/authPlugin.js';
  import { useUserStore } from '@common/store/index.js';

  const route = useRoute();
  const kinde = useKindeAuth();
  const userStore = useUserStore();

  const isAuth = computed(() => route.query?.code != null && route.query?.state != null);
  const isReady = computed(() => route.name != null && userStore.isReady && !isAuth.value);
</script>

<template>
  <TheNavigationBar v-if="isReady" />
  <RouterView v-if="isReady" />
  <KindeError v-if="kinde.hasError.value" />
  <DebugInfo v-if="kinde.isAuthenticated && userStore.isETAdmin && !kinde.hasError.value" />
  <EtLoader
    :loading="!isReady && !kinde.hasError.value"
    is-page />
</template>
