// @ts-check

/**
 * Sorts an array of objects by key. Its sorted asc
 * @example arr.sort(sortBy('name'));
 * @param {string|number} key Key to sort by
 * @returns {function(object, object): number} Function to be used for sorting
 */
export const sortBy = (key) => {
  return (a, b) => a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
};
