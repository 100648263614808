// @ts-check

import { useT } from '@common/i18n/i18n.js';
import { notice } from 'vue-ui-kit';
const t = useT();

/**
 * @typedef {import('../clients/clientHelpers.js').axiosError} axiosError
 */

/**
 * Should be one of api.key in da.js
 * @typedef {(
 * 'failedToRetrieve'|'failedToAdd'|'failedToDelete'|'failedToUpdate'|'failedToActivate'|'failedToDeactivate'|'failedToOpen'|'failedToClose'|
 * 'successfullyAdded'|'successfullyUpdated'|'successfullyDeleted'|'successfullyActivated'|'successfullyDeactivated'|'successfullyOpened'|'successfullyClosed'
 * )} translationKey
 */

/**
 * Show message as notice to user. From a try catch block with a ajax call
 * @param {translationKey} [key] Key in translation, eg. api.${key}
 * @param {string} [entity] entity in translation, et: api.entity.${entity}
 * @param {object} [err] Error object. From xxClient.js files. If set, then its shows danger notice with error else positive
 */
export const notifyAxiosToUser = (key, entity, err) => {
  if (err != null) {
    const errorMessage = err.isAxios ? (err.message || JSON.stringify(err.exception)) : (err.message || JSON.stringify(err));
    let preMessage = '';
    if (key != null && entity != null) {
      preMessage = t(`common.api.${key}`, { entity: t(`common.api.entity.${entity}`) });
    }

    const m = (preMessage ? `${preMessage}: ` : '') + errorMessage;
    notice.open({ message: m, variant: 'danger', duration: 10000 });
  } else {
    const message = t(`common.api.${key}`, { entity: t(`common.api.entity.${entity}`) });
    notice.open({ message, duration: 5000, variant: 'success' });
  }
};
