<template>
  <div v-if="kinde.isAuthenticated">
    <EtButton
      variant="primary-dark"
      @click="logout">
      {{ t('common.global.messages.logout') }}
    </EtButton>
  </div>
</template>

<script setup>
  import { EtButton } from 'vue-ui-kit';
  import { useKindeAuth } from '@common/composables/auth/authPlugin.js';
  import { useT } from '../../i18n/i18n.js';
  const t = useT();
  const kinde = useKindeAuth();

  const logout = () => {
    kinde.logout();
  };
</script>
