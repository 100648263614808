<template>
  <main class="layout-container">
    <h2>{{ t('title') }}</h2>
    <p>{{ t('body') }}</p>
    <LogOut />
  </main>
</template>

<script setup>
  import { useTranslate } from '../../i18n/i18n.js';
  import LogOut from '../user/LogOut.vue';
  const t = useTranslate('common.component.kindeError');
</script>
