// @ts-check

import axios from '@common/clients/propertyServiceClient.js';
import { useT } from '@common/i18n/i18n.js';
const t = useT();

/*
 * Fetch listings closing reasons
 */
export const getClosingReasons = async () => {
  const result = await axios.get('/closing-reasons');
  const data = result.data.closingReasons;
  const closingReasons = data.map(reasons => ({
    ...reasons,
    translatedLabel: t(
      `api.closingReasons.${reasons.name.toLowerCase()}`,
    ),
  }));

  return closingReasons;
};

/**
 * Get list of listing subtype categories.
 * TODO: Should be renamed to something like listingTypeSubcategoryIDs
 * @returns {Promise<{ID: number, name: string}[]>} ListingtypeSubcategories
 */
export const getListingtypeSubcategories = async () => {
  const result = await axios.get('/subtype-categories');
  return result.data.categories;
};

/**
 * Get list of usage proposals
 * @returns {Promise<{ID: number, name: string}[]>} UsageProposals
 */
export const getUsageProposals = async () => {
  const result = await axios.get('/usage-proposals');
  return result.data.usageProposals;
};

/**
 * Get list of facilities
 * @returns {Promise<{ID: number, name: string}[]>} Facilities
 */
export const getFacilities = async () => {
  const result = await axios.get('/facilities');
  return result.data.facilities;
};

/**
 * Get list of energy labels
 * @returns {Promise<{ID: number, name: string}[]>} EnergyLabels
 */
export const getEnergyLabels = async () => {
  const result = await axios.get('/energy-labels');
  return result.data.energyLabels;
};
