<template>
  <EtSelect
    v-if="(userStore.accounts.length > 1)"
    :model-value="userStore.activeAccount"
    :options="userStore.accounts"
    :placeholder="$t('component.accountSelect.selectAccountLabel')"
    :option-name="a => `${a.name}${a.billingId != null ? ` (${a.billingId})` : ''} - (${metaStore.getAccountTypeById(a.accountType)?.name ?? '?'})`"
    disable-empty
    hide-error
    @update:model-value="handleModelUpdate" />
</template>

<script setup>
  // @ts-check
  import { EtSelect } from 'vue-ui-kit';
  import { useUserStore, useMetaStore } from '@common/store/index.js';
  const userStore = useUserStore();
  const metaStore = useMetaStore();

  const props = defineProps({
    /**
     * Set to true if you want the page to reload when selecting a new account
     */
    reload: {
      type: Boolean,
      default: false,
    },
  });

  /**
   * Updates userstore with new selected account.
   * If you want to listen for change then watch userStore.activeAccount
   * watch(() => userStore.activeAccount, () => { ... });
   * @param {object} account The selected account
   */
  const handleModelUpdate = (account) => {
    userStore.setActiveAccountId(account.ID, props.reload);
  };
</script>
