// @ts-check

import { ref } from 'vue';
import createKindeClient from '@kinde-oss/kinde-auth-pkce-js';

/**
 * @typedef {import('@kinde-oss/kinde-auth-pkce-js').KindeClient} KindeClient
 * @typedef {import('@kinde-oss/kinde-auth-pkce-js').KindeUser} KindeUser
 * @typedef {import('vue-router').RouteLocation} RouteLocation
 * @typedef {{
 * isLoading: import('vue').Ref<boolean>,
 * isAuthenticated: import('vue').Ref<boolean>,
 * user: import('vue').Ref<KindeUser|null>,
 * hasError: import('vue').Ref<boolean>,
 * login: function(Partial<RouteLocation>) : Promise<void>,
 * logout: function() : Promise<void>,
 * getToken: function() : Promise<string|undefined>,
 * getPermissions: function() : string[],
 * }} KindeAuth
 */

/** @type {KindeClient} */
let kindeClient;

/** @type {KindeAuth} */
const kindeAuth = {
  isLoading: ref(true),
  isAuthenticated: ref(false),
  user: ref(null),
  hasError: ref(false),

  login: async (to) => {
    await kindeClient.login({
      app_state: {
        redirectTo: to.fullPath,
      },
    });
  },

  logout: async () => {
    await kindeClient.logout();
  },

  /**
   * Returns token to be used for backend calls
   * @returns {Promise<string|undefined>} Returns token
   */
  getToken: async () => {
    return await kindeClient.getToken();
  },

  /**
   * Returns array with the users permissions
   * @returns {string[]} Array with permissions
   */
  getPermissions: () => {
    const p = kindeClient.getPermissions();
    return p.permissions;
  },
};

// @ts-ignore - used by extranets cypress tests
if (window.Cypress) {
  // @ts-ignore
  window.__etKindeAuth = kindeAuth;
}

/**
 * Plugin for vue `app.use(kinde);`
 * Should be installed with vue-router in options
 */
export default {
  /**
   * Install auth plugin into vue app
   * @param {object} app Vue app object. Is not used for now.
   * @param {object} options Options send to install
   * @param {import('vue-router').Router} options.router Vue router
   * @param {string} options.clientId Kinde client id
   * @param {string} options.domain Kinde domain
   * @param {string} options.audience Kinde audience
   * @param {boolean} options.useLocalStorage Kinde is_dangerously_use_local_storage
   */
  install: (app, options) => {
    // @ts-ignore - used by extranets cypress tests
    if (window.Cypress) {
      return;
    }

    const getClient = async () => {
      kindeClient = await createKindeClient({
        client_id: options.clientId,
        domain: options.domain,
        audience: options.audience,
        redirect_uri: window.location.origin,
        is_dangerously_use_local_storage: options.useLocalStorage,
        /**
         * Called when user gets back from Kinde
         * @param {KindeUser} user Kinde user
         * @param {{redirectTo: string}|undefined} appState App state from kinde
         */
        on_redirect_callback: (user, appState) => {
          if (user) {
            kindeAuth.user.value = user;
            kindeAuth.isAuthenticated.value = true;
          }

          if (appState?.redirectTo && options.router) {
            options.router.replace(appState.redirectTo);
          }
        },
      });

      const q = new URLSearchParams(window.location.search);
      // If code and state in url, then some error with Kinde propably had happened.
      if (q.get('code') && q.get('state')) {
        kindeAuth.hasError.value = true;
        kindeAuth.isLoading.value = false;
        return;
      }

      // If we have a user, then user is authenticated
      const user = kindeClient.getUser();
      if (user) {
        kindeAuth.user.value = user;
        kindeAuth.isAuthenticated.value = true;
      }

      kindeAuth.isLoading.value = false;
    };

    getClient();
  },
};

/**
 * To be used in components
 * @example import { useKindeAuth } from 'authPlugin.js'; const kindeAuth = useKindeAuth();
 * @returns {KindeAuth} KindeAuth
 */
export const useKindeAuth = () => {
  return kindeAuth;
};
